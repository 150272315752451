import React, { useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import router from 'router'

function App() {
  useEffect(() => {
    // @ts-ignore
    import('preline')
  }, [])

  return (
      <>
        <RouterProvider router={router}/>
        <Toaster/>
      </>
  )
}

export default App
