import { useState } from 'react'
import AuthBox from 'modules/auth/components/AuthBox'
import ForgotPasswordForm from 'modules/auth/components/ForgotPasswordForm'
import Alert from 'components/alerts'

export default function ForgotPasswordPage() {
  const [success, setSuccess] = useState(false)

  const toggleSuccess = () => setSuccess(!success)

  return (
      <AuthBox
          title="Recupera tu contraseña"
          subtitle="¿Recuerdas tu contraseña?"
          subtitleLinkText="Ingresa aquí"
          subtitleLinkTo="/auth/login">
        {
          success
              ?
              <Alert
                  title="Revisa tu correo"
                  subtitle="Te enviamos un mensaje con un enlace para recuperar tu contraseña"
                  actions={[
                    {
                      title: 'Volver',
                      onClick: toggleSuccess
                    }
                  ]}/>
              :
              <ForgotPasswordForm onSuccess={toggleSuccess}/>
        }
      </AuthBox>
  )
}