import { LabelHTMLAttributes, ReactNode } from 'react'

interface Props extends LabelHTMLAttributes<HTMLLabelElement> {
  htmlFor: string,
  children: ReactNode
}

export default function Label({ htmlFor, children }: Props) {
  return (
      <label htmlFor={htmlFor} className="block text-sm mb-2 dark:text-white">
        {children}
      </label>
  )
}