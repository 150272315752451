import useAuth from 'modules/auth/hooks/useAuth'

export default function useUser() {
  const { user } = useAuth()

  if (!user)
    throw new Error('Attempted to use useUser() hook within an unauthenticated context. ' +
        'Validate if the user isAuthenticated with useAuth() hook')

  return user
}