import classNames from 'classnames';

type Color = 'blue' | 'black' | 'white'

interface Props {
  color?: Color
  className?: string
}

export default function Logo({color = 'black', className}: Props) {
  return (
      <svg
          className={className}
          version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="100px" height="100px" viewBox="0 0 500.000000 500.000000"
          preserveAspectRatio="xMidYMid meet">
        <metadata>
          Created by potrace 1.16, written by Peter Selinger 2001-2019
        </metadata>
        <g
            className={classNames({
              'fill-blue-600': color === 'blue',
              'fill-black': color === 'black',
              'fill-white': color === 'white',
            })}
            transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)" stroke="none">
          <path d="M180 2496 l0 -1606 425 0 425 0 2 1129 3 1129 120 -47 c307 -120 596
-316 790 -537 38 -44 134 -167 213 -274 366 -492 593 -726 899 -923 146 -95
494 -267 540 -267 11 0 13 82 13 444 l0 443 -89 57 c-264 167 -421 325 -719
723 -346 462 -517 632 -840 840 -287 185 -674 338 -1038 412 -152 31 -429 64
-631 77 l-113 7 0 -1607z"/>
          <path d="M3460 3690 l0 -410 260 0 260 0 0 -1154 0 -1154 73 -16 c164 -35 376
-58 575 -63 l202 -6 0 1607 0 1606 -685 0 -685 0 0 -410z"/>
        </g>
      </svg>
  )
}