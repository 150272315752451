import { Outlet } from 'react-router-dom'

export default function AuthPage() {
  return (
      <div className="dark:bg-slate-900 bg-gray-100 flex h-full items-center py-16">
        <div className="w-full max-w-md mx-auto p-6">
          <Outlet/>
        </div>
      </div>
  )
}