import AuthBox from 'modules/auth/components/AuthBox'
import CreateCompanyForm from 'modules/auth/components/CreateCompanyForm'

export default function CreateCompanyPage() {
  // TODO make this company login required
  return (
      <AuthBox
          title="Información de empresa"
          subtitle="Ya estás registrado, ahora necesitamos los datos de tu empresa para terminar.">
        <CreateCompanyForm/>
      </AuthBox>
  )
}