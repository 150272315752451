import React from 'react'
import { RecoilRoot } from 'recoil'
import ReactDOM from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'
import 'assets/styles/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import AuthProvider from 'modules/auth/context/AuthProvider'
import client from 'apollo/client'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)
root.render(
    <React.StrictMode>
      <RecoilRoot>
        <ApolloProvider client={client}>
          <AuthProvider>
            <App/>
          </AuthProvider>
        </ApolloProvider>
      </RecoilRoot>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
