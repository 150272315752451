import { ErrorMessage } from 'formik'

interface Props {
  name: string
}

export default function FormError({ name }: Props) {
  return (
      <ErrorMessage name={name}>
        {errorMessage => (
          <p className="text-sm text-red-600 mt-2">
            {errorMessage}
          </p>
        )}
      </ErrorMessage>
  )
}