import { ReactNode } from 'react'
import ChatUserAvatar from 'modules/chat/components/ChatUserAvatar'

interface Props {
  children: ReactNode
}

export default function ChatUserMessage({children}: Props) {
  return (
      <li className="py-2 sm:py-4">
        <div className="max-w-4xl px-4 sm:px-6 lg:px-8 mx-auto">
          <div className="max-w-2xl flex gap-x-2 sm:gap-x-4">
            <ChatUserAvatar/>
            <div className="grow mt-2 space-y-3">
              {children}
            </div>
          </div>
        </div>
      </li>
  )
}