import { atom } from 'recoil'

interface ImportedFile {
  id: number
  attributes: {
    file: {
      data: {
        attributes: {
          name: string
        }
      }
    }
  }
}

const chatState = atom<ImportedFile[]>({
  key: 'chatState',
  default: []
})

export default chatState