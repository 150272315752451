import { graphql } from 'gql'

const transactionsQuery = graphql(`
  query Transactions {
    transactions(sort: "date:desc") {
      data {
        id
        attributes {
          date
          name
          amount
          balance
        }
      }
    }
  }
`)

export default transactionsQuery