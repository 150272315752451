import Header from 'components/layouts/dashboard/Header'
import Breadcrumb from 'components/layouts/dashboard/Breadcrumb'
import Sidebar from 'components/layouts/dashboard/Sidebar'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

export default function DashboardLayout({ children }: Props) {
  return (
      <div className="bg-gray-50 dark:bg-slate-900 h-full">
        <Header/>
        <Breadcrumb/>
        <Sidebar/>
        <div className="bg-gray-50 dark:bg-slate-900">
          <div className="w-full pt-10 px-4 sm:px-6 md:px-8 lg:pl-72">
            {children}
          </div>
        </div>
      </div>
  )
}