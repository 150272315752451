import Logo from 'components/main/Logo'
import useUser from 'modules/auth/hooks/useUser'

export default function ChatHero() {
  const user = useUser()

  return (
      <div className="max-w-4xl px-4 sm:px-6 lg:px-8 mx-auto text-center">
        <Logo
            color="blue"
            className="w-20 h-auto mx-auto mb-4"/>
        <h1 className="text-3xl font-bold text-gray-800 sm:text-4xl dark:text-white">
          {/* TODO use firstName when available */}
          ¡Bienvenid@ a N1 {user.username}!
        </h1>
        <p className="mt-3 text-gray-600 dark:text-gray-400">
          Tu software contable con el poder de la inteligencia artificial
        </p>
      </div>
  )
}