import { graphql } from 'gql'
import { useQuery } from '@apollo/client'

const importedFilesQuery = graphql(`
  query ImportedFiles {
    importedFiles {
      data {
        id
        attributes {
          status
          type
          file {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`)

export default function HomePage() {
  const { data } = useQuery(importedFilesQuery)

  return (
      <>
        <ul>
          {
            data?.importedFiles?.data.map(importedFile => (
                <li
                    key={importedFile.id}
                    className="border-b border-b-gray-200 mb-5 pb-5">
                  <p>
                    Imported
                    file {importedFile.id} | {importedFile.attributes?.status} | {importedFile.attributes?.type}
                  </p>
                  <p>
                    {importedFile.attributes?.file.data?.attributes?.name}
                  </p>
                </li>
            ))
          }
        </ul>
        {/*<header>*/}
        {/*  <p className="mb-2 text-sm font-semibold text-blue-600">Homepage</p>*/}
        {/*  <h1 className="block text-2xl font-bold text-gray-800 sm:text-3xl dark:text-white">*/}
        {/*    Numero 1*/}
        {/*  </h1>*/}
        {/*  <p className="mt-2 text-lg text-gray-800 dark:text-gray-400">*/}
        {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vulputate id odio sed dictum. Nam scelerisque*/}
        {/*    tincidunt condimentum.*/}
        {/*  </p>*/}
        {/*</header>*/}
      </>
  )
}