import { ReactNode } from 'react'
import Toast from 'components/toasts/Toast'
import { Times } from 'components/icons'

interface Props {
  children: ReactNode
}

export default function ErrorToast({children}: Props) {
  return <Toast icon={<Times/>}>{children}</Toast>
}