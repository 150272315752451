import { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

export default function FormGrid({ children }: Props) {
  return (
      <div className="grid gap-y-4">
        {children}
      </div>
  )
}