import AuthBox from 'modules/auth/components/AuthBox'
import SignupForm from 'modules/auth/components/SignupForm'

export default function SignupPage() {
  return (
      <AuthBox
          title="Regístrate ahora"
          subtitle="¿Ya tienes una cuenta?"
          subtitleLinkTo="/auth/login"
          subtitleLinkText="Ingresa aquí">
        <SignupForm/>
      </AuthBox>
  )
}