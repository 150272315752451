import { ReactNode } from 'react'
import ChatBotAvatar from 'modules/chat/components/ChatBotAvatar'

interface Props {
  children: ReactNode
}

export default function ChatBotMessage({children}: Props) {
  return (
      <li className="max-w-4xl py-2 px-4 sm:px-6 lg:px-8 mx-auto flex gap-x-2 sm:gap-x-4">
        <ChatBotAvatar/>
        <div className="space-y-3">
          {children}
        </div>
      </li>
  )
}