import { ReactNode } from 'react'

interface Props {
  icon: ReactNode
  children: ReactNode
}

export default function Toast({icon, children}: Props) {
  return (
      <div className="max-w-xs bg-white border rounded-md shadow-lg dark:bg-gray-800 dark:border-gray-700" role="alert">
        <div className="flex p-4">
          <div className="flex-shrink-0">
            {icon}
          </div>
          <div className="ml-3">
            <p className="text-sm text-gray-700 dark:text-gray-400">
              {children}
            </p>
          </div>
        </div>
      </div>
  )
}