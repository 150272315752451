import * as Yup from 'yup'
import { Form, Formik, FormikHelpers, FormikValues } from 'formik'
import { FormError, FormGrid, Label, TextField } from 'components/forms'
import Button from 'components/buttons/Button'

interface Values {
  name: string
  nit: number | null
}

export default function CreateCompanyForm() {
  const initialValues: Values = {
    name: '',
    nit: null,
  }
  const validationSchema = Yup.object<Values>({
    name: Yup.string()
        .required('Este campo es requerido'),
    nit: Yup.number()
        .nullable()
        .required('Este campo es requerido'),
  })

  const handleSubmit = async (values: FormikValues, { setSubmitting }: FormikHelpers<Values>) => {
    // TODO implement logic
    // try {
    //   const {email, password} = values
    //   const username = email
    //   const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/local/register`, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({ username, email, password }),
    //   })
    //
    //   const data = await response.json()
    //   if (data?.error) {
    //     if (data.error?.name === 'ApplicationError')
    //         // TODO confirm this is the right error message
    //       toast.custom(() => (<ErrorToast>Éste correo electrónico ya está siendo usado.</ErrorToast>))
    //   } else {
    //     login(data.jwt, data.user)
    //     navigate('/auth/signup/company', { replace: true })
    //   }
    // } catch (error) {
    //   // TODO report to sentry?
    //   console.error(error)
    //   // setError(error?.message ?? "Something went wrong!")
    // } finally {
    //   // TODO set is submitting
    //   setSubmitting(false)
    // }
  }

  return (
      <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
            <Form>
              <FormGrid>
                <div>
                  <Label htmlFor="identifier">Nombre de tu empresa</Label>
                  <TextField label="Nombre de tu empresa" name="name" tabIndex={1}/>
                  <FormError name="name"/>
                </div>
                {/* TODO add verification digit? */}
                <div>
                  <Label htmlFor="nit">NIT</Label>
                  <TextField label="Contraseña" name="nit" type="number" tabIndex={2}/>
                  <FormError name="nit"/>
                </div>
                <Button type="submit" loading={isSubmitting} disabled={isSubmitting}>
                  Finalizar
                </Button>
              </FormGrid>
            </Form>
        )}
      </Formik>
  )
}