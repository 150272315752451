import * as Yup from 'yup'
import toast from 'react-hot-toast'
import { Form, Formik, FormikHelpers, FormikValues } from 'formik'
import { FormError, FormGrid, Label, TextField } from 'components/forms'
import Button from 'components/buttons/Button'
import { ErrorToast } from 'components/toasts'

interface Props {
  onSuccess: Function
}

interface Values {
  email: string
}

export default function ForgotPasswordForm({ onSuccess }: Props) {
  const initialValues: Values = {
    email: ''
  }
  const validationSchema = Yup.object<Values>({
    email: Yup.string().email('Correo inválido').required('Este campo es requerido'),
  })
  const handleSubmit = async (values: FormikValues, { setSubmitting }: FormikHelpers<Values>) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })

      const data = await response.json()
      if (data?.error) {
        console.log(data.error)
        // if (data.error?.name === 'ValidationError')
        toast.custom(() => (<ErrorToast>Ocurrió un error al intentar enviar el email de recuperación de contraseña</ErrorToast>))
      } else {
        onSuccess()
      }
    } catch (error) {
      // TODO report to sentry?
      console.error(error)
      // setError(error?.message ?? "Something went wrong!")
    } finally {
      // TODO set is submitting
      setSubmitting(false)
    }
  }

  return (
      <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
            <Form>
              <FormGrid>
                <div>
                  <Label htmlFor="email">Correo</Label>
                  <TextField label="Correo" name="email"/>
                  <FormError name="email"/>
                </div>
                <Button type="submit" loading={isSubmitting} disabled={isSubmitting}>
                  Enviar
                </Button>
              </FormGrid>
            </Form>
        )}
      </Formik>
  )
}