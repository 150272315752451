import { MouseEventHandler } from 'react'
import classNames from 'classnames'
import { Info } from 'components/icons'

type Level = 'info' | 'warning' | 'error'

interface Action {
  title: string
  onClick: MouseEventHandler<HTMLButtonElement>
}

interface Props {
  title: string
  subtitle?: string
  hideIcon?: boolean
  level?: Level
  actions?: [Action]
}

export default function Alert({ title, subtitle, hideIcon = false, level = 'info', actions }: Props) {
  // TODO use level to change colors
  return (
      <div
          className={classNames([
            'bg-blue-50 border border-blue-200 rounded-md p-4',
            !subtitle && 'text-sm text-gray-600'
          ])}
          role="alert">
        <div className={classNames([
          !hideIcon && 'flex'
        ])}>
          {
              !hideIcon &&
              <div className={classNames([
                'flex-shrink-0',
                subtitle ? 'pt-1' : 'pt-0.5'
              ])}>
                {/* TODO implement more icons */}
                {
                    level === 'info' && <Info/>
                }
              </div>
          }
          <div
              className={classNames([
                !hideIcon && 'ml-4'
              ])}>
            {
              (title && subtitle)
                  ?
                  <h3 className="text-gray-800 font-semibold">
                    {title}
                  </h3>
                  :
                  title
            }
            {
                subtitle &&
                <div className="mt-2 text-sm text-gray-600">
                  {subtitle}
                </div>
            }
            {
                actions &&
                <div className="mt-4">
                  <div className="flex space-x-3">
                    {
                      actions.map(action => (
                          // TODO use <Button> component
                          <button
                              type="button"
                              className="inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-medium text-blue-600 hover:underline focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm"
                              onClick={action.onClick}>
                            {action.title}
                          </button>
                      ))
                    }
                  </div>
                </div>
            }
          </div>
        </div>
      </div>
  )
}