import { Link, LinkProps } from 'react-router-dom'

interface Props extends LinkProps {
}

export default function InlineLink({ to, children }: Props) {
  return (
      <Link
          className="text-sm text-blue-600 decoration-2 hover:underline font-medium"
          to={to}>
        {children}
      </Link>
  )
}