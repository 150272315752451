import React from 'react'
import { UsersPermissionsUser } from 'gql/graphql'

interface AuthContextType {
  user: UsersPermissionsUser | null
  token: string
  isAuthenticated: boolean
  login: (jwt: string, user: UsersPermissionsUser) => void
  logout: () => void
}

const AuthContext = React.createContext<AuthContextType>(null!)

export default AuthContext