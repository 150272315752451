import * as Yup from 'yup'
import toast from 'react-hot-toast'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { Form, Formik, FormikHelpers, FormikValues } from 'formik'
import { FormError, FormGrid, Label, TextField } from 'components/forms'
import Button from 'components/buttons/Button'
import { ErrorToast } from 'components/toasts'
import { setToken, setUser } from 'modules/auth/services/storage'

interface Values {
  password: string
  passwordConfirmation: string
}

export default function ResetPasswordForm() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const initialValues: Values = {
    password: '',
    passwordConfirmation: '',
  }
  const validationSchema = Yup.object<Values>({
    password: Yup.string()
        .required('Este campo es requerido'),
    passwordConfirmation: Yup.string()
        .required('Este campo es requerido'),
  })
  const handleSubmit = async (values: FormikValues, { setSubmitting }: FormikHelpers<Values>) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...values, code: searchParams.get('code') }),
      })

      const data = await response.json()
      if (data?.error) {
        if (data.error?.name === 'ValidationError') {
          if (data.error.message === 'Incorrect code provided') {
            toast.custom(() => (<ErrorToast>El código ya expiró, debes generar uno nuevo</ErrorToast>))
            navigate('/auth/forgot-password', { replace: true })
          } else
            toast.custom(() => (<ErrorToast>Las contraseñas no coinciden</ErrorToast>))
        }
      } else {
        // TODO have a login function that does this, could be a hook
        setToken(data.jwt)
        setUser(data.user)
        navigate('/', { replace: true })
      }
    } catch (error) {
      // TODO report to sentry?
      console.error(error)
      toast.custom(() => (<ErrorToast>Ocurrió un error al enviar el email, intenta nuevamente más tarde</ErrorToast>))
      // setError(error?.message ?? "Something went wrong!")
    } finally {
      // TODO set is submitting
      setSubmitting(false)
    }
  }

  if (!searchParams.get('code'))
    return <Navigate to="/auth/login"/>

  return (
      <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
            <Form>
              <FormGrid>
                <div>
                  <Label htmlFor="password">Nueva contraseña</Label>
                  <TextField label="Contraseña" name="password" type="password" tabIndex={2}/>
                  <FormError name="password"/>
                </div>
                <div>
                  <Label htmlFor="passwordConfirmation">Confirmar contraseña</Label>
                  <TextField label="Contraseña" name="passwordConfirmation" type="password" tabIndex={2}/>
                  <FormError name="passwordConfirmation"/>
                </div>
                <Button type="submit" loading={isSubmitting} disabled={isSubmitting}>
                  Enviar
                </Button>
              </FormGrid>
            </Form>
        )}
      </Formik>
  )
}