import { createBrowserRouter, Navigate, Outlet, useLocation } from 'react-router-dom'
import HomePage from 'pages'
import AuthPage from 'pages/auth'
import LoginPage from 'pages/auth/LoginPage'
import ForgotPasswordPage from 'pages/auth/ForgotPasswordPage'
import LogoutPage from 'pages/auth/Logout'
import ResetPasswordPage from 'pages/auth/ResetPasswordPage'
import ChatPage from 'pages/chat'
import useAuth from 'modules/auth/hooks/useAuth'
import TransactionsPage from 'pages/expenses/TransactionsPage'
import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import SignupPage from 'pages/auth/SignupPage'
import CreateCompanyPage from 'pages/auth/CreateCompanyPage'

function ProtectedRoutes(){
  const location = useLocation()
  const { isAuthenticated } = useAuth()

  // TODO If authenticated, but no company, redirect to /auth/signup/company

  if (!isAuthenticated)
    return <Navigate to={{
      pathname: '/auth/login',
      search: `?from=${location.pathname}`
    }}/>

  if(location.pathname === '/chat')
    return <Outlet/>

  return (
      <DashboardLayout>
        <Outlet/>
      </DashboardLayout>
  )
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <ProtectedRoutes/>,
    children: [
      {
        path: '',
        element: <HomePage/>
      },
      {
        path: 'chat',
        element: <ChatPage/>
      },
      {
        path: 'expenses/transactions',
        element: <TransactionsPage/>
      },
    ]
  },
  {
    path: '/auth',
    element: <AuthPage/>,
    children: [
      {
        path: 'login',
        element: <LoginPage/>
      },
      {
        path: 'signup',
        element: <SignupPage/>
      },
      {
        path: 'signup/company',
        element: <CreateCompanyPage/>
      },
      {
        path: 'forgot-password',
        element: <ForgotPasswordPage/>
      },
      {
        path: 'reset-password',
        element: <ResetPasswordPage/>
      },
      {
        path: 'logout',
        element: <LogoutPage/>
      },
    ]
  }
])

export default router