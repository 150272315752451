import * as Yup from 'yup'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { Field, Form, Formik, FormikHelpers, FormikValues } from 'formik'
import { FormGrid, Label, TextField, FormError } from 'components/forms'
import Button from 'components/buttons/Button'
import { ErrorToast } from 'components/toasts'
import useAuth from 'modules/auth/hooks/useAuth'
import InlineLink from 'components/text/InlineLink'

interface Values {
  email: string
  password: string
  rePassword: string
  terms: boolean
}

export default function SignupForm() {
  const navigate = useNavigate()
  const { login } = useAuth()
  const initialValues: Values = {
    email: '',
    password: '',
    rePassword: '',
    terms: false
  }
  const termsErrorMsj = 'Debes aceptar nuestros términos y condiciones así como la política de privacidad, para poder continuar.'
  const validationSchema = Yup.object<Values>({
    email: Yup.string().email('Correo inválido').required('Este campo es requerido'),
    password: Yup.string()
        .required('Este campo es requerido'),
    rePassword: Yup.string()
        .required('Este campo es requerido'),
    terms: Yup.boolean()
        .required(termsErrorMsj)
        .oneOf([true], termsErrorMsj)
  })

  const handleSubmit = async (values: FormikValues, { setSubmitting }: FormikHelpers<Values>) => {
    try {
      const {email, password} = values
      const username = email
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/local/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, email, password }),
      })

      const data = await response.json()
      if (data?.error) {
        if (data.error?.name === 'ApplicationError')
          // TODO confirm this is the right error message
          toast.custom(() => (<ErrorToast>Éste correo electrónico ya está siendo usado.</ErrorToast>))
      } else {
        login(data.jwt, data.user)
        navigate('/auth/signup/company', { replace: true })
      }
    } catch (error) {
      // TODO report to sentry?
      console.error(error)
      // setError(error?.message ?? "Something went wrong!")
    } finally {
      // TODO set is submitting
      setSubmitting(false)
    }
  }

  return (
      <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
            <Form>
              <FormGrid>
                <div>
                  <Label htmlFor="identifier">Correo</Label>
                  <TextField label="Correo" name="email" type="email" tabIndex={1}/>
                  <FormError name="email"/>
                </div>
                <div>
                  <Label htmlFor="password">Contraseña</Label>
                  <TextField label="Contraseña" name="password" type="password" tabIndex={2}/>
                  <FormError name="password"/>
                </div>
                <div>
                  <Label htmlFor="rePassword">Confirmar contraseña</Label>
                  <TextField label="Contraseña" name="rePassword" type="password" tabIndex={3}/>
                  <FormError name="rePassword"/>
                </div>
                <div className="flex items-center">
                  <div className="flex">
                    {/* TODO Create reusable checkbox component */}
                    <Field id="terms" name="terms" type="checkbox" tabIndex={4}
                           className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"/>
                  </div>
                  <div className="ml-3">
                    <label htmlFor="terms" className="text-sm dark:text-white">
                      Acepto los <InlineLink to="/terms-and-conditions">términos y condiciones</InlineLink> y la <InlineLink to="/privacy-policy">política de privacidad</InlineLink>
                    </label>
                  </div>
                </div>
                <FormError name="terms"/>
                <Button type="submit" loading={isSubmitting} disabled={isSubmitting}>
                  Continuar
                </Button>
              </FormGrid>
            </Form>
        )}
      </Formik>
  )
}