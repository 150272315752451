/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * A date string, such as 2007-12-03, compliant with the `full-date` format
   * outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for
   * representation of dates and times using the Gregorian calendar.
   */
  Date: { input: any; output: any; }
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the
   * `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO
   * 8601 standard for representation of dates and times using the Gregorian calendar.
   */
  DateTime: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export type BooleanFilterInput = {
  and: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  between: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  contains: InputMaybe<Scalars['Boolean']['input']>;
  containsi: InputMaybe<Scalars['Boolean']['input']>;
  endsWith: InputMaybe<Scalars['Boolean']['input']>;
  eq: InputMaybe<Scalars['Boolean']['input']>;
  eqi: InputMaybe<Scalars['Boolean']['input']>;
  gt: InputMaybe<Scalars['Boolean']['input']>;
  gte: InputMaybe<Scalars['Boolean']['input']>;
  in: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  lt: InputMaybe<Scalars['Boolean']['input']>;
  lte: InputMaybe<Scalars['Boolean']['input']>;
  ne: InputMaybe<Scalars['Boolean']['input']>;
  not: InputMaybe<BooleanFilterInput>;
  notContains: InputMaybe<Scalars['Boolean']['input']>;
  notContainsi: InputMaybe<Scalars['Boolean']['input']>;
  notIn: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  notNull: InputMaybe<Scalars['Boolean']['input']>;
  null: InputMaybe<Scalars['Boolean']['input']>;
  or: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  startsWith: InputMaybe<Scalars['Boolean']['input']>;
};

export type Company = {
  __typename?: 'Company';
  contacts: Maybe<ContactRelationResponseCollection>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  product_categories: Maybe<ProductCategoryRelationResponseCollection>;
  publishedAt: Maybe<Scalars['DateTime']['output']>;
  slug: Maybe<Scalars['String']['output']>;
  transaction_categories: Maybe<TransactionCategoryRelationResponseCollection>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};


export type CompanyContactsArgs = {
  filters: InputMaybe<ContactFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type CompanyProduct_CategoriesArgs = {
  filters: InputMaybe<ProductCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type CompanyTransaction_CategoriesArgs = {
  filters: InputMaybe<TransactionCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyEntity = {
  __typename?: 'CompanyEntity';
  attributes: Maybe<Company>;
  id: Maybe<Scalars['ID']['output']>;
};

export type CompanyEntityResponse = {
  __typename?: 'CompanyEntityResponse';
  data: Maybe<CompanyEntity>;
};

export type CompanyEntityResponseCollection = {
  __typename?: 'CompanyEntityResponseCollection';
  data: Array<CompanyEntity>;
  meta: ResponseCollectionMeta;
};

export type CompanyFiltersInput = {
  and: InputMaybe<Array<InputMaybe<CompanyFiltersInput>>>;
  contacts: InputMaybe<ContactFiltersInput>;
  createdAt: InputMaybe<DateTimeFilterInput>;
  id: InputMaybe<IdFilterInput>;
  name: InputMaybe<StringFilterInput>;
  not: InputMaybe<CompanyFiltersInput>;
  or: InputMaybe<Array<InputMaybe<CompanyFiltersInput>>>;
  product_categories: InputMaybe<ProductCategoryFiltersInput>;
  publishedAt: InputMaybe<DateTimeFilterInput>;
  slug: InputMaybe<StringFilterInput>;
  transaction_categories: InputMaybe<TransactionCategoryFiltersInput>;
  updatedAt: InputMaybe<DateTimeFilterInput>;
};

export type CompanyInput = {
  contacts: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name: InputMaybe<Scalars['String']['input']>;
  product_categories: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  publishedAt: InputMaybe<Scalars['DateTime']['input']>;
  slug: InputMaybe<Scalars['String']['input']>;
  transaction_categories: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type ComponentEmailsAttachment = {
  __typename?: 'ComponentEmailsAttachment';
  file: UploadFileEntityResponse;
  id: Scalars['ID']['output'];
};

export type ComponentEmailsAttachmentFiltersInput = {
  and: InputMaybe<Array<InputMaybe<ComponentEmailsAttachmentFiltersInput>>>;
  not: InputMaybe<ComponentEmailsAttachmentFiltersInput>;
  or: InputMaybe<Array<InputMaybe<ComponentEmailsAttachmentFiltersInput>>>;
};

export type ComponentEmailsAttachmentInput = {
  file: InputMaybe<Scalars['ID']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
};

export type Contact = {
  __typename?: 'Contact';
  address_line_1: Scalars['String']['output'];
  address_line_2: Maybe<Scalars['String']['output']>;
  company: Maybe<CompanyEntityResponse>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  electronic_invoices: Maybe<ElectronicInvoiceRelationResponseCollection>;
  email: Maybe<Scalars['String']['output']>;
  first_name: Maybe<Scalars['String']['output']>;
  id_number: Scalars['String']['output'];
  id_type: Enum_Contact_Id_Type;
  is_client: Maybe<Scalars['Boolean']['output']>;
  is_supplier: Maybe<Scalars['Boolean']['output']>;
  last_name: Maybe<Scalars['String']['output']>;
  legal_name: Maybe<Scalars['String']['output']>;
  person_type: Enum_Contact_Person_Type;
  phone: Maybe<Scalars['String']['output']>;
  postal_code: Maybe<Scalars['Int']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};


export type ContactElectronic_InvoicesArgs = {
  filters: InputMaybe<ElectronicInvoiceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContactEntity = {
  __typename?: 'ContactEntity';
  attributes: Maybe<Contact>;
  id: Maybe<Scalars['ID']['output']>;
};

export type ContactEntityResponse = {
  __typename?: 'ContactEntityResponse';
  data: Maybe<ContactEntity>;
};

export type ContactEntityResponseCollection = {
  __typename?: 'ContactEntityResponseCollection';
  data: Array<ContactEntity>;
  meta: ResponseCollectionMeta;
};

export type ContactFiltersInput = {
  address_line_1: InputMaybe<StringFilterInput>;
  address_line_2: InputMaybe<StringFilterInput>;
  and: InputMaybe<Array<InputMaybe<ContactFiltersInput>>>;
  company: InputMaybe<CompanyFiltersInput>;
  createdAt: InputMaybe<DateTimeFilterInput>;
  electronic_invoices: InputMaybe<ElectronicInvoiceFiltersInput>;
  email: InputMaybe<StringFilterInput>;
  first_name: InputMaybe<StringFilterInput>;
  id: InputMaybe<IdFilterInput>;
  id_number: InputMaybe<StringFilterInput>;
  id_type: InputMaybe<StringFilterInput>;
  is_client: InputMaybe<BooleanFilterInput>;
  is_supplier: InputMaybe<BooleanFilterInput>;
  last_name: InputMaybe<StringFilterInput>;
  legal_name: InputMaybe<StringFilterInput>;
  not: InputMaybe<ContactFiltersInput>;
  or: InputMaybe<Array<InputMaybe<ContactFiltersInput>>>;
  person_type: InputMaybe<StringFilterInput>;
  phone: InputMaybe<StringFilterInput>;
  postal_code: InputMaybe<IntFilterInput>;
  updatedAt: InputMaybe<DateTimeFilterInput>;
};

export type ContactInput = {
  address_line_1: InputMaybe<Scalars['String']['input']>;
  address_line_2: InputMaybe<Scalars['String']['input']>;
  company: InputMaybe<Scalars['ID']['input']>;
  electronic_invoices: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  email: InputMaybe<Scalars['String']['input']>;
  first_name: InputMaybe<Scalars['String']['input']>;
  id_number: InputMaybe<Scalars['String']['input']>;
  id_type: InputMaybe<Enum_Contact_Id_Type>;
  is_client: InputMaybe<Scalars['Boolean']['input']>;
  is_supplier: InputMaybe<Scalars['Boolean']['input']>;
  last_name: InputMaybe<Scalars['String']['input']>;
  legal_name: InputMaybe<Scalars['String']['input']>;
  person_type: InputMaybe<Enum_Contact_Person_Type>;
  phone: InputMaybe<Scalars['String']['input']>;
  postal_code: InputMaybe<Scalars['Int']['input']>;
};

export type ContactRelationResponseCollection = {
  __typename?: 'ContactRelationResponseCollection';
  data: Array<ContactEntity>;
};

export type DateFilterInput = {
  and: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  between: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  contains: InputMaybe<Scalars['Date']['input']>;
  containsi: InputMaybe<Scalars['Date']['input']>;
  endsWith: InputMaybe<Scalars['Date']['input']>;
  eq: InputMaybe<Scalars['Date']['input']>;
  eqi: InputMaybe<Scalars['Date']['input']>;
  gt: InputMaybe<Scalars['Date']['input']>;
  gte: InputMaybe<Scalars['Date']['input']>;
  in: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  lt: InputMaybe<Scalars['Date']['input']>;
  lte: InputMaybe<Scalars['Date']['input']>;
  ne: InputMaybe<Scalars['Date']['input']>;
  not: InputMaybe<DateFilterInput>;
  notContains: InputMaybe<Scalars['Date']['input']>;
  notContainsi: InputMaybe<Scalars['Date']['input']>;
  notIn: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  notNull: InputMaybe<Scalars['Boolean']['input']>;
  null: InputMaybe<Scalars['Boolean']['input']>;
  or: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  startsWith: InputMaybe<Scalars['Date']['input']>;
};

export type DateTimeFilterInput = {
  and: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  between: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  contains: InputMaybe<Scalars['DateTime']['input']>;
  containsi: InputMaybe<Scalars['DateTime']['input']>;
  endsWith: InputMaybe<Scalars['DateTime']['input']>;
  eq: InputMaybe<Scalars['DateTime']['input']>;
  eqi: InputMaybe<Scalars['DateTime']['input']>;
  gt: InputMaybe<Scalars['DateTime']['input']>;
  gte: InputMaybe<Scalars['DateTime']['input']>;
  in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt: InputMaybe<Scalars['DateTime']['input']>;
  lte: InputMaybe<Scalars['DateTime']['input']>;
  ne: InputMaybe<Scalars['DateTime']['input']>;
  not: InputMaybe<DateTimeFilterInput>;
  notContains: InputMaybe<Scalars['DateTime']['input']>;
  notContainsi: InputMaybe<Scalars['DateTime']['input']>;
  notIn: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  notNull: InputMaybe<Scalars['Boolean']['input']>;
  null: InputMaybe<Scalars['Boolean']['input']>;
  or: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  startsWith: InputMaybe<Scalars['DateTime']['input']>;
};

export enum Enum_Contact_Id_Type {
  Cc = 'CC',
  Ce = 'CE',
  Die = 'DIE',
  ForeignNit = 'FOREIGN_NIT',
  Nit = 'NIT',
  Nuip = 'NUIP',
  Pep = 'PEP',
  Pp = 'PP',
  Rc = 'RC',
  Te = 'TE',
  Ti = 'TI'
}

export enum Enum_Contact_Person_Type {
  Legal = 'legal',
  Natural = 'natural'
}

export enum Enum_Importedfile_Status {
  Error = 'error',
  New = 'new',
  Success = 'success'
}

export enum Enum_Importedfile_Type {
  BankExtract = 'bank_extract',
  Bill = 'bill',
  Dian = 'dian',
  ElectronicInvoice = 'electronic_invoice',
  Invoice = 'invoice'
}

export type ElectronicInvoice = {
  __typename?: 'ElectronicInvoice';
  contact: Maybe<ContactEntityResponse>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  electronic_invoice_lines: Maybe<ElectronicInvoiceLineRelationResponseCollection>;
  imported_file: Maybe<ImportedFileEntityResponse>;
  issued_at: Scalars['DateTime']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};


export type ElectronicInvoiceElectronic_Invoice_LinesArgs = {
  filters: InputMaybe<ElectronicInvoiceLineFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ElectronicInvoiceEntity = {
  __typename?: 'ElectronicInvoiceEntity';
  attributes: Maybe<ElectronicInvoice>;
  id: Maybe<Scalars['ID']['output']>;
};

export type ElectronicInvoiceEntityResponse = {
  __typename?: 'ElectronicInvoiceEntityResponse';
  data: Maybe<ElectronicInvoiceEntity>;
};

export type ElectronicInvoiceEntityResponseCollection = {
  __typename?: 'ElectronicInvoiceEntityResponseCollection';
  data: Array<ElectronicInvoiceEntity>;
  meta: ResponseCollectionMeta;
};

export type ElectronicInvoiceFiltersInput = {
  and: InputMaybe<Array<InputMaybe<ElectronicInvoiceFiltersInput>>>;
  contact: InputMaybe<ContactFiltersInput>;
  createdAt: InputMaybe<DateTimeFilterInput>;
  electronic_invoice_lines: InputMaybe<ElectronicInvoiceLineFiltersInput>;
  id: InputMaybe<IdFilterInput>;
  imported_file: InputMaybe<ImportedFileFiltersInput>;
  issued_at: InputMaybe<DateTimeFilterInput>;
  not: InputMaybe<ElectronicInvoiceFiltersInput>;
  or: InputMaybe<Array<InputMaybe<ElectronicInvoiceFiltersInput>>>;
  updatedAt: InputMaybe<DateTimeFilterInput>;
};

export type ElectronicInvoiceInput = {
  contact: InputMaybe<Scalars['ID']['input']>;
  electronic_invoice_lines: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  imported_file: InputMaybe<Scalars['ID']['input']>;
  issued_at: InputMaybe<Scalars['DateTime']['input']>;
};

export type ElectronicInvoiceLine = {
  __typename?: 'ElectronicInvoiceLine';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  discount: Maybe<Scalars['Float']['output']>;
  electronic_invoice: Maybe<ElectronicInvoiceEntityResponse>;
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  product: Maybe<ProductEntityResponse>;
  quantity: Scalars['Int']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type ElectronicInvoiceLineEntity = {
  __typename?: 'ElectronicInvoiceLineEntity';
  attributes: Maybe<ElectronicInvoiceLine>;
  id: Maybe<Scalars['ID']['output']>;
};

export type ElectronicInvoiceLineEntityResponse = {
  __typename?: 'ElectronicInvoiceLineEntityResponse';
  data: Maybe<ElectronicInvoiceLineEntity>;
};

export type ElectronicInvoiceLineEntityResponseCollection = {
  __typename?: 'ElectronicInvoiceLineEntityResponseCollection';
  data: Array<ElectronicInvoiceLineEntity>;
  meta: ResponseCollectionMeta;
};

export type ElectronicInvoiceLineFiltersInput = {
  and: InputMaybe<Array<InputMaybe<ElectronicInvoiceLineFiltersInput>>>;
  createdAt: InputMaybe<DateTimeFilterInput>;
  discount: InputMaybe<FloatFilterInput>;
  electronic_invoice: InputMaybe<ElectronicInvoiceFiltersInput>;
  id: InputMaybe<IdFilterInput>;
  name: InputMaybe<StringFilterInput>;
  not: InputMaybe<ElectronicInvoiceLineFiltersInput>;
  or: InputMaybe<Array<InputMaybe<ElectronicInvoiceLineFiltersInput>>>;
  price: InputMaybe<FloatFilterInput>;
  product: InputMaybe<ProductFiltersInput>;
  quantity: InputMaybe<IntFilterInput>;
  updatedAt: InputMaybe<DateTimeFilterInput>;
};

export type ElectronicInvoiceLineInput = {
  discount: InputMaybe<Scalars['Float']['input']>;
  electronic_invoice: InputMaybe<Scalars['ID']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  price: InputMaybe<Scalars['Float']['input']>;
  product: InputMaybe<Scalars['ID']['input']>;
  quantity: InputMaybe<Scalars['Int']['input']>;
};

export type ElectronicInvoiceLineRelationResponseCollection = {
  __typename?: 'ElectronicInvoiceLineRelationResponseCollection';
  data: Array<ElectronicInvoiceLineEntity>;
};

export type ElectronicInvoiceRelationResponseCollection = {
  __typename?: 'ElectronicInvoiceRelationResponseCollection';
  data: Array<ElectronicInvoiceEntity>;
};

export type Email = {
  __typename?: 'Email';
  attachments: Maybe<Array<Maybe<ComponentEmailsAttachment>>>;
  body: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  from: Scalars['String']['output'];
  imported_files: Maybe<ImportedFileRelationResponseCollection>;
  sender_ip: Scalars['String']['output'];
  subject: Maybe<Scalars['String']['output']>;
  to: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};


export type EmailAttachmentsArgs = {
  filters: InputMaybe<ComponentEmailsAttachmentFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type EmailImported_FilesArgs = {
  filters: InputMaybe<ImportedFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type EmailEntity = {
  __typename?: 'EmailEntity';
  attributes: Maybe<Email>;
  id: Maybe<Scalars['ID']['output']>;
};

export type EmailEntityResponse = {
  __typename?: 'EmailEntityResponse';
  data: Maybe<EmailEntity>;
};

export type EmailEntityResponseCollection = {
  __typename?: 'EmailEntityResponseCollection';
  data: Array<EmailEntity>;
  meta: ResponseCollectionMeta;
};

export type EmailFiltersInput = {
  and: InputMaybe<Array<InputMaybe<EmailFiltersInput>>>;
  attachments: InputMaybe<ComponentEmailsAttachmentFiltersInput>;
  body: InputMaybe<StringFilterInput>;
  createdAt: InputMaybe<DateTimeFilterInput>;
  from: InputMaybe<StringFilterInput>;
  id: InputMaybe<IdFilterInput>;
  imported_files: InputMaybe<ImportedFileFiltersInput>;
  not: InputMaybe<EmailFiltersInput>;
  or: InputMaybe<Array<InputMaybe<EmailFiltersInput>>>;
  sender_ip: InputMaybe<StringFilterInput>;
  subject: InputMaybe<StringFilterInput>;
  to: InputMaybe<StringFilterInput>;
  updatedAt: InputMaybe<DateTimeFilterInput>;
};

export type EmailInput = {
  attachments: InputMaybe<Array<InputMaybe<ComponentEmailsAttachmentInput>>>;
  body: InputMaybe<Scalars['String']['input']>;
  from: InputMaybe<Scalars['String']['input']>;
  imported_files: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  sender_ip: InputMaybe<Scalars['String']['input']>;
  subject: InputMaybe<Scalars['String']['input']>;
  to: InputMaybe<Scalars['String']['input']>;
};

export type FileInfoInput = {
  alternativeText: InputMaybe<Scalars['String']['input']>;
  caption: InputMaybe<Scalars['String']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
};

export type FloatFilterInput = {
  and: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  between: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  contains: InputMaybe<Scalars['Float']['input']>;
  containsi: InputMaybe<Scalars['Float']['input']>;
  endsWith: InputMaybe<Scalars['Float']['input']>;
  eq: InputMaybe<Scalars['Float']['input']>;
  eqi: InputMaybe<Scalars['Float']['input']>;
  gt: InputMaybe<Scalars['Float']['input']>;
  gte: InputMaybe<Scalars['Float']['input']>;
  in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt: InputMaybe<Scalars['Float']['input']>;
  lte: InputMaybe<Scalars['Float']['input']>;
  ne: InputMaybe<Scalars['Float']['input']>;
  not: InputMaybe<FloatFilterInput>;
  notContains: InputMaybe<Scalars['Float']['input']>;
  notContainsi: InputMaybe<Scalars['Float']['input']>;
  notIn: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  notNull: InputMaybe<Scalars['Boolean']['input']>;
  null: InputMaybe<Scalars['Boolean']['input']>;
  or: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  startsWith: InputMaybe<Scalars['Float']['input']>;
};

export type GenericMorph = Company | ComponentEmailsAttachment | Contact | ElectronicInvoice | ElectronicInvoiceLine | Email | I18NLocale | ImportedFile | Product | ProductCategory | Transaction | TransactionCategory | UploadFile | UploadFolder | UsersPermissionsPermission | UsersPermissionsRole | UsersPermissionsUser;

export type I18NLocale = {
  __typename?: 'I18NLocale';
  code: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  name: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity';
  attributes: Maybe<I18NLocale>;
  id: Maybe<Scalars['ID']['output']>;
};

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse';
  data: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
  and: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  code: InputMaybe<StringFilterInput>;
  createdAt: InputMaybe<DateTimeFilterInput>;
  id: InputMaybe<IdFilterInput>;
  name: InputMaybe<StringFilterInput>;
  not: InputMaybe<I18NLocaleFiltersInput>;
  or: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  updatedAt: InputMaybe<DateTimeFilterInput>;
};

export type IdFilterInput = {
  and: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  between: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contains: InputMaybe<Scalars['ID']['input']>;
  containsi: InputMaybe<Scalars['ID']['input']>;
  endsWith: InputMaybe<Scalars['ID']['input']>;
  eq: InputMaybe<Scalars['ID']['input']>;
  eqi: InputMaybe<Scalars['ID']['input']>;
  gt: InputMaybe<Scalars['ID']['input']>;
  gte: InputMaybe<Scalars['ID']['input']>;
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  lt: InputMaybe<Scalars['ID']['input']>;
  lte: InputMaybe<Scalars['ID']['input']>;
  ne: InputMaybe<Scalars['ID']['input']>;
  not: InputMaybe<IdFilterInput>;
  notContains: InputMaybe<Scalars['ID']['input']>;
  notContainsi: InputMaybe<Scalars['ID']['input']>;
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  notNull: InputMaybe<Scalars['Boolean']['input']>;
  null: InputMaybe<Scalars['Boolean']['input']>;
  or: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  startsWith: InputMaybe<Scalars['ID']['input']>;
};

export type ImportedFile = {
  __typename?: 'ImportedFile';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  email: Maybe<EmailEntityResponse>;
  error_message: Maybe<Scalars['String']['output']>;
  file: UploadFileEntityResponse;
  status: Enum_Importedfile_Status;
  transactions: Maybe<TransactionRelationResponseCollection>;
  type: Maybe<Enum_Importedfile_Type>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};


export type ImportedFileTransactionsArgs = {
  filters: InputMaybe<TransactionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ImportedFileEntity = {
  __typename?: 'ImportedFileEntity';
  attributes: Maybe<ImportedFile>;
  id: Maybe<Scalars['ID']['output']>;
};

export type ImportedFileEntityResponse = {
  __typename?: 'ImportedFileEntityResponse';
  data: Maybe<ImportedFileEntity>;
};

export type ImportedFileEntityResponseCollection = {
  __typename?: 'ImportedFileEntityResponseCollection';
  data: Array<ImportedFileEntity>;
  meta: ResponseCollectionMeta;
};

export type ImportedFileFiltersInput = {
  and: InputMaybe<Array<InputMaybe<ImportedFileFiltersInput>>>;
  createdAt: InputMaybe<DateTimeFilterInput>;
  email: InputMaybe<EmailFiltersInput>;
  error_message: InputMaybe<StringFilterInput>;
  id: InputMaybe<IdFilterInput>;
  not: InputMaybe<ImportedFileFiltersInput>;
  or: InputMaybe<Array<InputMaybe<ImportedFileFiltersInput>>>;
  status: InputMaybe<StringFilterInput>;
  transactions: InputMaybe<TransactionFiltersInput>;
  type: InputMaybe<StringFilterInput>;
  updatedAt: InputMaybe<DateTimeFilterInput>;
};

export type ImportedFileInput = {
  email: InputMaybe<Scalars['ID']['input']>;
  error_message: InputMaybe<Scalars['String']['input']>;
  file: InputMaybe<Scalars['ID']['input']>;
  status: InputMaybe<Enum_Importedfile_Status>;
  transactions: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  type: InputMaybe<Enum_Importedfile_Type>;
};

export type ImportedFileRelationResponseCollection = {
  __typename?: 'ImportedFileRelationResponseCollection';
  data: Array<ImportedFileEntity>;
};

export type IntFilterInput = {
  and: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  between: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  contains: InputMaybe<Scalars['Int']['input']>;
  containsi: InputMaybe<Scalars['Int']['input']>;
  endsWith: InputMaybe<Scalars['Int']['input']>;
  eq: InputMaybe<Scalars['Int']['input']>;
  eqi: InputMaybe<Scalars['Int']['input']>;
  gt: InputMaybe<Scalars['Int']['input']>;
  gte: InputMaybe<Scalars['Int']['input']>;
  in: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt: InputMaybe<Scalars['Int']['input']>;
  lte: InputMaybe<Scalars['Int']['input']>;
  ne: InputMaybe<Scalars['Int']['input']>;
  not: InputMaybe<IntFilterInput>;
  notContains: InputMaybe<Scalars['Int']['input']>;
  notContainsi: InputMaybe<Scalars['Int']['input']>;
  notIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  notNull: InputMaybe<Scalars['Boolean']['input']>;
  null: InputMaybe<Scalars['Boolean']['input']>;
  or: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  startsWith: InputMaybe<Scalars['Int']['input']>;
};

export type JsonFilterInput = {
  and: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  between: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  contains: InputMaybe<Scalars['JSON']['input']>;
  containsi: InputMaybe<Scalars['JSON']['input']>;
  endsWith: InputMaybe<Scalars['JSON']['input']>;
  eq: InputMaybe<Scalars['JSON']['input']>;
  eqi: InputMaybe<Scalars['JSON']['input']>;
  gt: InputMaybe<Scalars['JSON']['input']>;
  gte: InputMaybe<Scalars['JSON']['input']>;
  in: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  lt: InputMaybe<Scalars['JSON']['input']>;
  lte: InputMaybe<Scalars['JSON']['input']>;
  ne: InputMaybe<Scalars['JSON']['input']>;
  not: InputMaybe<JsonFilterInput>;
  notContains: InputMaybe<Scalars['JSON']['input']>;
  notContainsi: InputMaybe<Scalars['JSON']['input']>;
  notIn: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  notNull: InputMaybe<Scalars['Boolean']['input']>;
  null: InputMaybe<Scalars['Boolean']['input']>;
  or: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  startsWith: InputMaybe<Scalars['JSON']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Change user password. Confirm with the current password. */
  changePassword: Maybe<UsersPermissionsLoginPayload>;
  createCompany: Maybe<CompanyEntityResponse>;
  createContact: Maybe<ContactEntityResponse>;
  createElectronicInvoice: Maybe<ElectronicInvoiceEntityResponse>;
  createElectronicInvoiceLine: Maybe<ElectronicInvoiceLineEntityResponse>;
  createEmail: Maybe<EmailEntityResponse>;
  createImportedFile: Maybe<ImportedFileEntityResponse>;
  createProduct: Maybe<ProductEntityResponse>;
  createProductCategory: Maybe<ProductCategoryEntityResponse>;
  createTransaction: Maybe<TransactionEntityResponse>;
  createTransactionCategory: Maybe<TransactionCategoryEntityResponse>;
  createUploadFile: Maybe<UploadFileEntityResponse>;
  createUploadFolder: Maybe<UploadFolderEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole: Maybe<UsersPermissionsCreateRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteCompany: Maybe<CompanyEntityResponse>;
  deleteContact: Maybe<ContactEntityResponse>;
  deleteElectronicInvoice: Maybe<ElectronicInvoiceEntityResponse>;
  deleteElectronicInvoiceLine: Maybe<ElectronicInvoiceLineEntityResponse>;
  deleteEmail: Maybe<EmailEntityResponse>;
  deleteImportedFile: Maybe<ImportedFileEntityResponse>;
  deleteProduct: Maybe<ProductEntityResponse>;
  deleteProductCategory: Maybe<ProductCategoryEntityResponse>;
  deleteTransaction: Maybe<TransactionEntityResponse>;
  deleteTransactionCategory: Maybe<TransactionCategoryEntityResponse>;
  deleteUploadFile: Maybe<UploadFileEntityResponse>;
  deleteUploadFolder: Maybe<UploadFolderEntityResponse>;
  /** Delete an existing role */
  deleteUsersPermissionsRole: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  /** Confirm an email users email address */
  emailConfirmation: Maybe<UsersPermissionsLoginPayload>;
  /** Request a reset password token */
  forgotPassword: Maybe<UsersPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  removeFile: Maybe<UploadFileEntityResponse>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword: Maybe<UsersPermissionsLoginPayload>;
  updateCompany: Maybe<CompanyEntityResponse>;
  updateContact: Maybe<ContactEntityResponse>;
  updateElectronicInvoice: Maybe<ElectronicInvoiceEntityResponse>;
  updateElectronicInvoiceLine: Maybe<ElectronicInvoiceLineEntityResponse>;
  updateEmail: Maybe<EmailEntityResponse>;
  updateFileInfo: UploadFileEntityResponse;
  updateImportedFile: Maybe<ImportedFileEntityResponse>;
  updateProduct: Maybe<ProductEntityResponse>;
  updateProductCategory: Maybe<ProductCategoryEntityResponse>;
  updateTransaction: Maybe<TransactionEntityResponse>;
  updateTransactionCategory: Maybe<TransactionCategoryEntityResponse>;
  updateUploadFile: Maybe<UploadFileEntityResponse>;
  updateUploadFolder: Maybe<UploadFolderEntityResponse>;
  /** Update an existing role */
  updateUsersPermissionsRole: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  upload: UploadFileEntityResponse;
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};


export type MutationCreateCompanyArgs = {
  data: CompanyInput;
};


export type MutationCreateContactArgs = {
  data: ContactInput;
};


export type MutationCreateElectronicInvoiceArgs = {
  data: ElectronicInvoiceInput;
};


export type MutationCreateElectronicInvoiceLineArgs = {
  data: ElectronicInvoiceLineInput;
};


export type MutationCreateEmailArgs = {
  data: EmailInput;
};


export type MutationCreateImportedFileArgs = {
  data: ImportedFileInput;
};


export type MutationCreateProductArgs = {
  data: ProductInput;
};


export type MutationCreateProductCategoryArgs = {
  data: ProductCategoryInput;
};


export type MutationCreateTransactionArgs = {
  data: TransactionInput;
};


export type MutationCreateTransactionCategoryArgs = {
  data: TransactionCategoryInput;
};


export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};


export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput;
};


export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};


export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteContactArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteElectronicInvoiceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteElectronicInvoiceLineArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteEmailArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteImportedFileArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteProductCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTransactionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTransactionCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUploadFolderArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String']['input'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};


export type MutationMultipleUploadArgs = {
  field: InputMaybe<Scalars['String']['input']>;
  files: Array<InputMaybe<Scalars['Upload']['input']>>;
  ref: InputMaybe<Scalars['String']['input']>;
  refId: InputMaybe<Scalars['ID']['input']>;
};


export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};


export type MutationRemoveFileArgs = {
  id: Scalars['ID']['input'];
};


export type MutationResetPasswordArgs = {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};


export type MutationUpdateCompanyArgs = {
  data: CompanyInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateContactArgs = {
  data: ContactInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateElectronicInvoiceArgs = {
  data: ElectronicInvoiceInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateElectronicInvoiceLineArgs = {
  data: ElectronicInvoiceLineInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateEmailArgs = {
  data: EmailInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID']['input'];
  info: InputMaybe<FileInfoInput>;
};


export type MutationUpdateImportedFileArgs = {
  data: ImportedFileInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateProductArgs = {
  data: ProductInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateProductCategoryArgs = {
  data: ProductCategoryInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateTransactionArgs = {
  data: TransactionInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateTransactionCategoryArgs = {
  data: TransactionCategoryInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateUploadFileArgs = {
  data: UploadFileInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateUploadFolderArgs = {
  data: UploadFolderInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
  id: Scalars['ID']['input'];
};


export type MutationUploadArgs = {
  field: InputMaybe<Scalars['String']['input']>;
  file: Scalars['Upload']['input'];
  info: InputMaybe<FileInfoInput>;
  ref: InputMaybe<Scalars['String']['input']>;
  refId: InputMaybe<Scalars['ID']['input']>;
};

export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int']['output'];
  pageCount: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginationArg = {
  limit: InputMaybe<Scalars['Int']['input']>;
  page: InputMaybe<Scalars['Int']['input']>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  start: InputMaybe<Scalars['Int']['input']>;
};

export type Product = {
  __typename?: 'Product';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  discount: Maybe<Scalars['Float']['output']>;
  electronic_invoice_lines: Maybe<ElectronicInvoiceLineRelationResponseCollection>;
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  product_categories: Maybe<ProductCategoryRelationResponseCollection>;
  stock: Maybe<Scalars['Int']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};


export type ProductElectronic_Invoice_LinesArgs = {
  filters: InputMaybe<ElectronicInvoiceLineFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type ProductProduct_CategoriesArgs = {
  filters: InputMaybe<ProductCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  company: Maybe<CompanyEntityResponse>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  products: Maybe<ProductRelationResponseCollection>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};


export type ProductCategoryProductsArgs = {
  filters: InputMaybe<ProductFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ProductCategoryEntity = {
  __typename?: 'ProductCategoryEntity';
  attributes: Maybe<ProductCategory>;
  id: Maybe<Scalars['ID']['output']>;
};

export type ProductCategoryEntityResponse = {
  __typename?: 'ProductCategoryEntityResponse';
  data: Maybe<ProductCategoryEntity>;
};

export type ProductCategoryEntityResponseCollection = {
  __typename?: 'ProductCategoryEntityResponseCollection';
  data: Array<ProductCategoryEntity>;
  meta: ResponseCollectionMeta;
};

export type ProductCategoryFiltersInput = {
  and: InputMaybe<Array<InputMaybe<ProductCategoryFiltersInput>>>;
  company: InputMaybe<CompanyFiltersInput>;
  createdAt: InputMaybe<DateTimeFilterInput>;
  id: InputMaybe<IdFilterInput>;
  name: InputMaybe<StringFilterInput>;
  not: InputMaybe<ProductCategoryFiltersInput>;
  or: InputMaybe<Array<InputMaybe<ProductCategoryFiltersInput>>>;
  products: InputMaybe<ProductFiltersInput>;
  updatedAt: InputMaybe<DateTimeFilterInput>;
};

export type ProductCategoryInput = {
  company: InputMaybe<Scalars['ID']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  products: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type ProductCategoryRelationResponseCollection = {
  __typename?: 'ProductCategoryRelationResponseCollection';
  data: Array<ProductCategoryEntity>;
};

export type ProductEntity = {
  __typename?: 'ProductEntity';
  attributes: Maybe<Product>;
  id: Maybe<Scalars['ID']['output']>;
};

export type ProductEntityResponse = {
  __typename?: 'ProductEntityResponse';
  data: Maybe<ProductEntity>;
};

export type ProductEntityResponseCollection = {
  __typename?: 'ProductEntityResponseCollection';
  data: Array<ProductEntity>;
  meta: ResponseCollectionMeta;
};

export type ProductFiltersInput = {
  and: InputMaybe<Array<InputMaybe<ProductFiltersInput>>>;
  createdAt: InputMaybe<DateTimeFilterInput>;
  discount: InputMaybe<FloatFilterInput>;
  electronic_invoice_lines: InputMaybe<ElectronicInvoiceLineFiltersInput>;
  id: InputMaybe<IdFilterInput>;
  name: InputMaybe<StringFilterInput>;
  not: InputMaybe<ProductFiltersInput>;
  or: InputMaybe<Array<InputMaybe<ProductFiltersInput>>>;
  price: InputMaybe<FloatFilterInput>;
  product_categories: InputMaybe<ProductCategoryFiltersInput>;
  stock: InputMaybe<IntFilterInput>;
  updatedAt: InputMaybe<DateTimeFilterInput>;
};

export type ProductInput = {
  discount: InputMaybe<Scalars['Float']['input']>;
  electronic_invoice_lines: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name: InputMaybe<Scalars['String']['input']>;
  price: InputMaybe<Scalars['Float']['input']>;
  product_categories: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  stock: InputMaybe<Scalars['Int']['input']>;
};

export type ProductRelationResponseCollection = {
  __typename?: 'ProductRelationResponseCollection';
  data: Array<ProductEntity>;
};

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW'
}

export type Query = {
  __typename?: 'Query';
  companies: Maybe<CompanyEntityResponseCollection>;
  company: Maybe<CompanyEntityResponse>;
  contact: Maybe<ContactEntityResponse>;
  contacts: Maybe<ContactEntityResponseCollection>;
  electronicInvoice: Maybe<ElectronicInvoiceEntityResponse>;
  electronicInvoiceLine: Maybe<ElectronicInvoiceLineEntityResponse>;
  electronicInvoiceLines: Maybe<ElectronicInvoiceLineEntityResponseCollection>;
  electronicInvoices: Maybe<ElectronicInvoiceEntityResponseCollection>;
  email: Maybe<EmailEntityResponse>;
  emails: Maybe<EmailEntityResponseCollection>;
  i18NLocale: Maybe<I18NLocaleEntityResponse>;
  i18NLocales: Maybe<I18NLocaleEntityResponseCollection>;
  importedFile: Maybe<ImportedFileEntityResponse>;
  importedFiles: Maybe<ImportedFileEntityResponseCollection>;
  me: Maybe<UsersPermissionsMe>;
  product: Maybe<ProductEntityResponse>;
  productCategories: Maybe<ProductCategoryEntityResponseCollection>;
  productCategory: Maybe<ProductCategoryEntityResponse>;
  products: Maybe<ProductEntityResponseCollection>;
  transaction: Maybe<TransactionEntityResponse>;
  transactionCategories: Maybe<TransactionCategoryEntityResponseCollection>;
  transactionCategory: Maybe<TransactionCategoryEntityResponse>;
  transactions: Maybe<TransactionEntityResponseCollection>;
  uploadFile: Maybe<UploadFileEntityResponse>;
  uploadFiles: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder: Maybe<UploadFolderEntityResponse>;
  uploadFolders: Maybe<UploadFolderEntityResponseCollection>;
  usersPermissionsRole: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers: Maybe<UsersPermissionsUserEntityResponseCollection>;
};


export type QueryCompaniesArgs = {
  filters: InputMaybe<CompanyFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryCompanyArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
};


export type QueryContactArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
};


export type QueryContactsArgs = {
  filters: InputMaybe<ContactFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryElectronicInvoiceArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
};


export type QueryElectronicInvoiceLineArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
};


export type QueryElectronicInvoiceLinesArgs = {
  filters: InputMaybe<ElectronicInvoiceLineFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryElectronicInvoicesArgs = {
  filters: InputMaybe<ElectronicInvoiceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryEmailArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
};


export type QueryEmailsArgs = {
  filters: InputMaybe<EmailFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryI18NLocaleArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
};


export type QueryI18NLocalesArgs = {
  filters: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryImportedFileArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
};


export type QueryImportedFilesArgs = {
  filters: InputMaybe<ImportedFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryProductArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
};


export type QueryProductCategoriesArgs = {
  filters: InputMaybe<ProductCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryProductCategoryArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
};


export type QueryProductsArgs = {
  filters: InputMaybe<ProductFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryTransactionArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
};


export type QueryTransactionCategoriesArgs = {
  filters: InputMaybe<TransactionCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryTransactionCategoryArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
};


export type QueryTransactionsArgs = {
  filters: InputMaybe<TransactionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryUploadFileArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUploadFilesArgs = {
  filters: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryUploadFolderArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUploadFoldersArgs = {
  filters: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryUsersPermissionsRoleArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUsersPermissionsRolesArgs = {
  filters: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryUsersPermissionsUserArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUsersPermissionsUsersArgs = {
  filters: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export type StringFilterInput = {
  and: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  between: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains: InputMaybe<Scalars['String']['input']>;
  containsi: InputMaybe<Scalars['String']['input']>;
  endsWith: InputMaybe<Scalars['String']['input']>;
  eq: InputMaybe<Scalars['String']['input']>;
  eqi: InputMaybe<Scalars['String']['input']>;
  gt: InputMaybe<Scalars['String']['input']>;
  gte: InputMaybe<Scalars['String']['input']>;
  in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt: InputMaybe<Scalars['String']['input']>;
  lte: InputMaybe<Scalars['String']['input']>;
  ne: InputMaybe<Scalars['String']['input']>;
  not: InputMaybe<StringFilterInput>;
  notContains: InputMaybe<Scalars['String']['input']>;
  notContainsi: InputMaybe<Scalars['String']['input']>;
  notIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notNull: InputMaybe<Scalars['Boolean']['input']>;
  null: InputMaybe<Scalars['Boolean']['input']>;
  or: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith: InputMaybe<Scalars['String']['input']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  amount: Scalars['Float']['output'];
  balance: Maybe<Scalars['Float']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  date: Scalars['Date']['output'];
  description: Maybe<Scalars['String']['output']>;
  imported_file: Maybe<ImportedFileEntityResponse>;
  name: Maybe<Scalars['String']['output']>;
  transaction_categories: Maybe<TransactionCategoryRelationResponseCollection>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};


export type TransactionTransaction_CategoriesArgs = {
  filters: InputMaybe<TransactionCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TransactionCategory = {
  __typename?: 'TransactionCategory';
  company: Maybe<CompanyEntityResponse>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  transactions: Maybe<TransactionRelationResponseCollection>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};


export type TransactionCategoryTransactionsArgs = {
  filters: InputMaybe<TransactionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TransactionCategoryEntity = {
  __typename?: 'TransactionCategoryEntity';
  attributes: Maybe<TransactionCategory>;
  id: Maybe<Scalars['ID']['output']>;
};

export type TransactionCategoryEntityResponse = {
  __typename?: 'TransactionCategoryEntityResponse';
  data: Maybe<TransactionCategoryEntity>;
};

export type TransactionCategoryEntityResponseCollection = {
  __typename?: 'TransactionCategoryEntityResponseCollection';
  data: Array<TransactionCategoryEntity>;
  meta: ResponseCollectionMeta;
};

export type TransactionCategoryFiltersInput = {
  and: InputMaybe<Array<InputMaybe<TransactionCategoryFiltersInput>>>;
  company: InputMaybe<CompanyFiltersInput>;
  createdAt: InputMaybe<DateTimeFilterInput>;
  id: InputMaybe<IdFilterInput>;
  name: InputMaybe<StringFilterInput>;
  not: InputMaybe<TransactionCategoryFiltersInput>;
  or: InputMaybe<Array<InputMaybe<TransactionCategoryFiltersInput>>>;
  transactions: InputMaybe<TransactionFiltersInput>;
  updatedAt: InputMaybe<DateTimeFilterInput>;
};

export type TransactionCategoryInput = {
  company: InputMaybe<Scalars['ID']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  transactions: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type TransactionCategoryRelationResponseCollection = {
  __typename?: 'TransactionCategoryRelationResponseCollection';
  data: Array<TransactionCategoryEntity>;
};

export type TransactionEntity = {
  __typename?: 'TransactionEntity';
  attributes: Maybe<Transaction>;
  id: Maybe<Scalars['ID']['output']>;
};

export type TransactionEntityResponse = {
  __typename?: 'TransactionEntityResponse';
  data: Maybe<TransactionEntity>;
};

export type TransactionEntityResponseCollection = {
  __typename?: 'TransactionEntityResponseCollection';
  data: Array<TransactionEntity>;
  meta: ResponseCollectionMeta;
};

export type TransactionFiltersInput = {
  amount: InputMaybe<FloatFilterInput>;
  and: InputMaybe<Array<InputMaybe<TransactionFiltersInput>>>;
  balance: InputMaybe<FloatFilterInput>;
  createdAt: InputMaybe<DateTimeFilterInput>;
  date: InputMaybe<DateFilterInput>;
  description: InputMaybe<StringFilterInput>;
  id: InputMaybe<IdFilterInput>;
  imported_file: InputMaybe<ImportedFileFiltersInput>;
  name: InputMaybe<StringFilterInput>;
  not: InputMaybe<TransactionFiltersInput>;
  or: InputMaybe<Array<InputMaybe<TransactionFiltersInput>>>;
  transaction_categories: InputMaybe<TransactionCategoryFiltersInput>;
  updatedAt: InputMaybe<DateTimeFilterInput>;
};

export type TransactionInput = {
  amount: InputMaybe<Scalars['Float']['input']>;
  balance: InputMaybe<Scalars['Float']['input']>;
  date: InputMaybe<Scalars['Date']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  imported_file: InputMaybe<Scalars['ID']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  transaction_categories: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type TransactionRelationResponseCollection = {
  __typename?: 'TransactionRelationResponseCollection';
  data: Array<TransactionEntity>;
};

export type UploadFile = {
  __typename?: 'UploadFile';
  alternativeText: Maybe<Scalars['String']['output']>;
  caption: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  ext: Maybe<Scalars['String']['output']>;
  formats: Maybe<Scalars['JSON']['output']>;
  hash: Scalars['String']['output'];
  height: Maybe<Scalars['Int']['output']>;
  mime: Scalars['String']['output'];
  name: Scalars['String']['output'];
  previewUrl: Maybe<Scalars['String']['output']>;
  provider: Scalars['String']['output'];
  provider_metadata: Maybe<Scalars['JSON']['output']>;
  related: Maybe<Array<Maybe<GenericMorph>>>;
  size: Scalars['Float']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  width: Maybe<Scalars['Int']['output']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  attributes: Maybe<UploadFile>;
  id: Maybe<Scalars['ID']['output']>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
  alternativeText: InputMaybe<StringFilterInput>;
  and: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  caption: InputMaybe<StringFilterInput>;
  createdAt: InputMaybe<DateTimeFilterInput>;
  ext: InputMaybe<StringFilterInput>;
  folder: InputMaybe<UploadFolderFiltersInput>;
  folderPath: InputMaybe<StringFilterInput>;
  formats: InputMaybe<JsonFilterInput>;
  hash: InputMaybe<StringFilterInput>;
  height: InputMaybe<IntFilterInput>;
  id: InputMaybe<IdFilterInput>;
  mime: InputMaybe<StringFilterInput>;
  name: InputMaybe<StringFilterInput>;
  not: InputMaybe<UploadFileFiltersInput>;
  or: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  previewUrl: InputMaybe<StringFilterInput>;
  provider: InputMaybe<StringFilterInput>;
  provider_metadata: InputMaybe<JsonFilterInput>;
  size: InputMaybe<FloatFilterInput>;
  updatedAt: InputMaybe<DateTimeFilterInput>;
  url: InputMaybe<StringFilterInput>;
  width: InputMaybe<IntFilterInput>;
};

export type UploadFileInput = {
  alternativeText: InputMaybe<Scalars['String']['input']>;
  caption: InputMaybe<Scalars['String']['input']>;
  ext: InputMaybe<Scalars['String']['input']>;
  folder: InputMaybe<Scalars['ID']['input']>;
  folderPath: InputMaybe<Scalars['String']['input']>;
  formats: InputMaybe<Scalars['JSON']['input']>;
  hash: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['Int']['input']>;
  mime: InputMaybe<Scalars['String']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  previewUrl: InputMaybe<Scalars['String']['input']>;
  provider: InputMaybe<Scalars['String']['input']>;
  provider_metadata: InputMaybe<Scalars['JSON']['input']>;
  size: InputMaybe<Scalars['Float']['input']>;
  url: InputMaybe<Scalars['String']['input']>;
  width: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
};

export type UploadFolder = {
  __typename?: 'UploadFolder';
  children: Maybe<UploadFolderRelationResponseCollection>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  files: Maybe<UploadFileRelationResponseCollection>;
  name: Scalars['String']['output'];
  parent: Maybe<UploadFolderEntityResponse>;
  path: Scalars['String']['output'];
  pathId: Scalars['Int']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};


export type UploadFolderChildrenArgs = {
  filters: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type UploadFolderFilesArgs = {
  filters: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity';
  attributes: Maybe<UploadFolder>;
  id: Maybe<Scalars['ID']['output']>;
};

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse';
  data: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
  and: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  children: InputMaybe<UploadFolderFiltersInput>;
  createdAt: InputMaybe<DateTimeFilterInput>;
  files: InputMaybe<UploadFileFiltersInput>;
  id: InputMaybe<IdFilterInput>;
  name: InputMaybe<StringFilterInput>;
  not: InputMaybe<UploadFolderFiltersInput>;
  or: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  parent: InputMaybe<UploadFolderFiltersInput>;
  path: InputMaybe<StringFilterInput>;
  pathId: InputMaybe<IntFilterInput>;
  updatedAt: InputMaybe<DateTimeFilterInput>;
};

export type UploadFolderInput = {
  children: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  files: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name: InputMaybe<Scalars['String']['input']>;
  parent: InputMaybe<Scalars['ID']['input']>;
  path: InputMaybe<Scalars['String']['input']>;
  pathId: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String']['input'];
  password: Scalars['String']['input'];
  provider: Scalars['String']['input'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt: Maybe<Scalars['String']['output']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  blocked: Maybe<Scalars['Boolean']['output']>;
  confirmed: Maybe<Scalars['Boolean']['output']>;
  email: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  role: Maybe<UsersPermissionsMeRole>;
  username: Scalars['String']['output'];
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Maybe<Scalars['String']['output']>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String']['output'];
  createdAt: Maybe<Scalars['DateTime']['output']>;
  role: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  attributes: Maybe<UsersPermissionsPermission>;
  id: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsPermissionFiltersInput = {
  action: InputMaybe<StringFilterInput>;
  and: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  createdAt: InputMaybe<DateTimeFilterInput>;
  id: InputMaybe<IdFilterInput>;
  not: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  or: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  role: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt: InputMaybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  permissions: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  users: Maybe<UsersPermissionsUserRelationResponseCollection>;
};


export type UsersPermissionsRolePermissionsArgs = {
  filters: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type UsersPermissionsRoleUsersArgs = {
  filters: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  attributes: Maybe<UsersPermissionsRole>;
  id: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
  and: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  createdAt: InputMaybe<DateTimeFilterInput>;
  description: InputMaybe<StringFilterInput>;
  id: InputMaybe<IdFilterInput>;
  name: InputMaybe<StringFilterInput>;
  not: InputMaybe<UsersPermissionsRoleFiltersInput>;
  or: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  permissions: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  type: InputMaybe<StringFilterInput>;
  updatedAt: InputMaybe<DateTimeFilterInput>;
  users: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  description: InputMaybe<Scalars['String']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  permissions: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  type: InputMaybe<Scalars['String']['input']>;
  users: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  blocked: Maybe<Scalars['Boolean']['output']>;
  confirmed: Maybe<Scalars['Boolean']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  provider: Maybe<Scalars['String']['output']>;
  role: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  username: Scalars['String']['output'];
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  attributes: Maybe<UsersPermissionsUser>;
  id: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  and: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  blocked: InputMaybe<BooleanFilterInput>;
  confirmationToken: InputMaybe<StringFilterInput>;
  confirmed: InputMaybe<BooleanFilterInput>;
  createdAt: InputMaybe<DateTimeFilterInput>;
  email: InputMaybe<StringFilterInput>;
  id: InputMaybe<IdFilterInput>;
  not: InputMaybe<UsersPermissionsUserFiltersInput>;
  or: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  password: InputMaybe<StringFilterInput>;
  provider: InputMaybe<StringFilterInput>;
  resetPasswordToken: InputMaybe<StringFilterInput>;
  role: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt: InputMaybe<DateTimeFilterInput>;
  username: InputMaybe<StringFilterInput>;
};

export type UsersPermissionsUserInput = {
  blocked: InputMaybe<Scalars['Boolean']['input']>;
  confirmationToken: InputMaybe<Scalars['String']['input']>;
  confirmed: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Scalars['String']['input']>;
  password: InputMaybe<Scalars['String']['input']>;
  provider: InputMaybe<Scalars['String']['input']>;
  resetPasswordToken: InputMaybe<Scalars['String']['input']>;
  role: InputMaybe<Scalars['ID']['input']>;
  username: InputMaybe<Scalars['String']['input']>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};

export type TransactionsQueryVariables = Exact<{ [key: string]: never; }>;


export type TransactionsQuery = { __typename?: 'Query', transactions: { __typename?: 'TransactionEntityResponseCollection', data: Array<{ __typename?: 'TransactionEntity', id: string | null, attributes: { __typename?: 'Transaction', date: any, name: string | null, amount: number, balance: number | null } | null }> } | null };

export type ImportedFilesQueryVariables = Exact<{ [key: string]: never; }>;


export type ImportedFilesQuery = { __typename?: 'Query', importedFiles: { __typename?: 'ImportedFileEntityResponseCollection', data: Array<{ __typename?: 'ImportedFileEntity', id: string | null, attributes: { __typename?: 'ImportedFile', status: Enum_Importedfile_Status, type: Enum_Importedfile_Type | null, file: { __typename?: 'UploadFileEntityResponse', data: { __typename?: 'UploadFileEntity', id: string | null, attributes: { __typename?: 'UploadFile', name: string } | null } | null } } | null }> } | null };


export const TransactionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Transactions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"transactions"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"sort"},"value":{"kind":"StringValue","value":"date:desc","block":false}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"attributes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"date"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"amount"}},{"kind":"Field","name":{"kind":"Name","value":"balance"}}]}}]}}]}}]}}]} as unknown as DocumentNode<TransactionsQuery, TransactionsQueryVariables>;
export const ImportedFilesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ImportedFiles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"importedFiles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"attributes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"file"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"attributes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<ImportedFilesQuery, ImportedFilesQueryVariables>;