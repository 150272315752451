import { lazy, Suspense } from 'react'
import ChatHero from 'modules/chat/components/ChatHero'
import ChatMessages from 'modules/chat/components/ChatMessages'

const ChatInput = lazy(() => import('modules/chat/components/ChatInput'))

export default function ChatAI() {
  return (
      <div className="relative h-full">
        {/* TODO fix this min height, maybe better to have the chat input absolutely positioned or to have a flex layout of rows? */}
        <div className="py-10 lg:py-14 min-h-[81vh]">
          <ChatHero/>
          <ChatMessages/>
        </div>
        <Suspense>
          <ChatInput/>
        </Suspense>
      </div>
  )
}