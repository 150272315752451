import * as Yup from 'yup'
import toast from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Form, Formik, FormikHelpers, FormikValues } from 'formik'
import { FormError, FormGrid, Label, TextField } from 'components/forms'
import InlineLink from 'components/text/InlineLink'
import Button from 'components/buttons/Button'
import { ErrorToast } from 'components/toasts'
import useAuth from 'modules/auth/hooks/useAuth'

interface Values {
  identifier: string
  password: string
}

export default function LoginForm() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { login } = useAuth()
  const initialValues: Values = {
    identifier: '',
    password: ''
  }
  const validationSchema = Yup.object<Values>({
    identifier: Yup.string().email('Correo inválido').required('Este campo es requerido'),
    password: Yup.string()
        .required('Este campo es requerido'),
  })

  const handleSubmit = async (values: FormikValues, { setSubmitting }: FormikHelpers<Values>) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/local`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })

      const data = await response.json()
      if (data?.error) {
        if (data.error?.name === 'ValidationError')
          toast.custom(() => (<ErrorToast>El correo o la contraseña son inválidos</ErrorToast>))
      } else {
        const from = searchParams.get('from')
        login(data.jwt, data.user)
        navigate(from ? from : '/', { replace: true })
      }
    } catch (error) {
      // TODO report to sentry?
      console.error(error)
      // setError(error?.message ?? "Something went wrong!")
    } finally {
      // TODO set is submitting
      setSubmitting(false)
    }
  }

  return (
      <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
            <Form>
              <FormGrid>
                <div>
                  <Label htmlFor="identifier">Correo</Label>
                  <TextField label="Correo" name="identifier" type="email" tabIndex={1}/>
                  <FormError name="identifier"/>
                </div>
                <div>
                  <div className="flex justify-between items-center">
                    <Label htmlFor="password">Contraseña</Label>
                    <InlineLink to="/auth/forgot-password" tabIndex={3}>
                      ¿Olvidaste la contraseña?
                    </InlineLink>
                  </div>
                  <TextField label="Contraseña" name="password" type="password" tabIndex={2}/>
                  <FormError name="password"/>
                </div>
                <div className="flex items-center">
                  <div className="flex">
                    <input id="remember-me" name="remember-me" type="checkbox"
                           className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"/>
                  </div>
                  <div className="ml-3">
                    <label htmlFor="remember-me" className="text-sm dark:text-white">Recordarme</label>
                  </div>
                </div>
                <Button type="submit" loading={isSubmitting} disabled={isSubmitting}>
                  Ingresar
                </Button>
              </FormGrid>
            </Form>
        )}
      </Formik>
  )
}