import AuthBox from 'modules/auth/components/AuthBox'
import LoginForm from 'modules/auth/components/LoginForm'

export default function LoginPage() {
  return (
      <AuthBox
          title="Ingresar"
          subtitle="¿Aún no tienes una cuenta?"
          subtitleLinkText="Crea una aquí"
          subtitleLinkTo="/auth/signup">
        <LoginForm/>
      </AuthBox>
  )
}