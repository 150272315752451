import { ReactNode, useEffect, useState } from 'react'
import AuthContext from 'modules/auth/context/AuthContext'
import {
  getToken as getTokenLocalStorage,
  setToken as setTokenLocalStorage,
  deleteToken as deleteTokenLocalStorage,
  getUser as getUserLocalStorage,
  setUser as setUserLocalStorage,
  deleteUser as deleteUserLocalStorage,
} from 'modules/auth/services/storage'
import { UsersPermissionsUser } from 'gql/graphql'

interface Props {
  children: ReactNode
}

export default function AuthProvider({ children }: Props) {
  const [token, setToken] = useState<string>(getTokenLocalStorage())
  const [user, setUser] = useState<UsersPermissionsUser | null>(getUserLocalStorage())

  useEffect(() => {
    if (user) {
      setTokenLocalStorage(token)
      setUserLocalStorage(user)
    } else {
      deleteTokenLocalStorage()
      deleteUserLocalStorage()
    }
  }, [token, user])

  const login = (jwt: string, user: UsersPermissionsUser) => {
    setToken(jwt)
    setUser(user)
  }

  const logout = () => {
    setToken('')
    setUser(null)
  }

  return (
      <AuthContext.Provider
          value={{
            user,
            token,
            isAuthenticated: user !== null,
            login,
            logout
          }}>
        {children}
      </AuthContext.Provider>
  )
}